<script setup lang="ts">
import type { JamBaseLinkAs } from './JamBaseLink.vue';

export type JamBaseNotificationVariant = 'error' | 'info';
export type JamBaseNotificationLink = {
    as?: JamBaseLinkAs;
    disabled?: boolean;
    href?: string | null;
    target?: string | null;
    text?: string;
};

const props = defineProps({
    closeButton: {
        default: false,
        type: Boolean,
    },
    icon: {
        default: '',
        type: String,
    },
    link: {
        default: null,
        type: Object as PropType<JamBaseNotificationLink | null>,
    },
    onClose: {
        default: () => true,
        type: Function as PropType<() => boolean>,
    },
    variant: {
        default: 'info',
        type: String as PropType<JamBaseNotificationVariant>,
    },
});
const show = ref<boolean>(true);
const closeMe = () => {
    if (props.onClose()) {
        show.value = false;
    }
};
</script>

<template>
    <div
        v-if="show"
        class="inline-flex min-w-[200px] max-w-[500px] items-center justify-start gap-3 rounded px-5 py-4 shadow"
        :class="{
            'bg-red-700': variant === 'error',
            'bg-gray-900': variant === 'info',
        }"
    >
        <div v-if="icon">
            <JamBaseIcon v-if="icon" :icon-name="icon" class="text-white" />
        </div>
        <div
            class="flex shrink grow basis-0 justify-stretch gap-7 leading-normal text-white"
        >
            <div class="flex grow">
                <div><slot /></div>
            </div>
            <JamBaseLink
                v-if="link && link.href && link.text"
                variant="white"
                :as="link.as"
                :href="link.href"
                :target="link.target || ''"
                :disabled="link.disabled"
            >
                {{ link.text }}
            </JamBaseLink>
        </div>
        <div v-if="closeButton" class="flex items-center">
            <button
                type="button"
                class="text-base text-white/50 transition-all hover:text-white"
                @click="closeMe"
            >
                <JamBaseIcon icon-name="close" />
            </button>
        </div>
    </div>
</template>
